<template>
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<div class="chatbox">
    <div class="chatbox__header">
         <button class="md-button md-button-clear" @click="clear">Reset</button>
        <div class="chatbox__headerText">{{userName}}

          <div class="chatbox__onlineDot"></div>
        </div>
           <select class="material-dropdown" v-model="aiConfig">
             <option v-for="option in configsList" :key="option" :value="option">
              {{ option }}
             </option>
           </select>
    </div>
    <div class="chatbox__messages">
        <div class="chatbox__messageBox" v-for="(message, index) in messages" :key="index" :class="{'chatbox__messageBox--primary': message.primary}">
            <div class="chatbox__message" :class="{'chatbox__message--primary': message.primary}" v-bind:style="{background: message.primary ? chatColor:'inital'}">
                <span v-html="message.text"></span>
                <div class="chatbox__tooltip chatbox__tooltip--left">{{message.date}}</div>
            </div>
        </div>
    </div>
    <div class="spinner" v-bind:style="[spinnerOn ? {'display': 'flex'} : {'display': 'none'}]"></div>
    <div class="chatbox__inputPanel">
      <div class="chat-container">
        <textarea  @keyup.enter="sendMessage" ref="chatInput" v-model="userInput" class="chat-input" placeholder="Type your message..." @input="autoResize"></textarea>
        <button @click="sendMessage" class="send-button">
        <svg stroke="currentColor" fill="none" stroke-width="2"
             viewBox="0 0 24 24"
             stroke-linecap="round"
             stroke-linejoin="round"
             class="airplane"
             height="1em" width="1em"
             xmlns="http://www.w3.org/2000/svg"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg></button>
      </div>


    </div>
</div>
</template>

<script>
import { io } from 'socket.io-client';
import moment from 'moment';

export default {
  name: 'ChatApp',
  data() {
    return {
      chatColor: '#0084ff',
      colorPanelShown: false,
      newMessage: '',
      tutorialSeen: false,
      colors: ['#0084ff','#ffc300','#4af844',
             '#7646ff','#a695c7','#ff5ca1',
             '#fa3c4c','#f56b78','#33343f'],
      userInput: '',
      userName: '',
      userNameSet: false,
      spinnerOn: false,
      passNumber: "",
      clientId: "",
      aiConfig: "",
      configsList: [],
      messages: [
          { sender: 'Bot', text: "Please enter your name:", primary: false, date: moment(new Date).format('hh:mm') }
      ]
    };
  },
  mounted() {
    if (localStorage.passNumber) {
      this.passNumber = localStorage.passNumber;
    }
    if (localStorage.clientId) {
      this.clientId = localStorage.clientId;
    }
    if (localStorage.aiConfig) {
      this.clientId = localStorage.aiConfig;
    }
  },
  watch: {
    passNumber(newPassNumber) {
      localStorage.passNumber = newPassNumber;
    },
    clientId(newClientId) {
      localStorage.clientId = newClientId;
    },
    aiConfig(newAIConfig) {
      localStorage.aiConfig = newAIConfig;
    }

  },
  methods: {
    setColor: function(color){
      this.chatColor = color;
      this.colorPanelShown = false;
    },
    autoResize() {
      this.$refs.chatInput.style.height = 'auto';
      this.$refs.chatInput.style.height = '1em'; // Set initial height to one line
      this.$refs.chatInput.style.height = this.$refs.chatInput.scrollHeight + 'px';
    },
    set_initial() {
      this.passNumber = "";
      this.clientId = "";
      this.userName = "Anonymous";
      this.aiConfig = "";
      this.userNameSet = false;
      this.messages = [{ sender: 'Bot', text: "Please enter your name:",
        primary: false, date: moment(new Date).format('hh:mm') }]
    },
    clear() {
      this.set_initial()
    },
    get_configs_from_server() {
      this.socket.emit('refresh_config', {});
    },
    sendMessage() {
      const userMessage = this.userInput.trim();
      if (userMessage !== '') {
        const msgObject = {
          sender: this.userName,
          text: userMessage,
          date: moment(new Date).format('hh:mm'),
          primary: true,
          passNumber: this.passNumber,
          clientId: this.clientId,
          aiConfig: this.aiConfig
        }

        this.messages.push(msgObject);
        this.userInput = '';
        this.tutorialSeen = true;
        this.spinnerOn = true;
        if (!this.userNameSet) {
          this.userNameSet = true;
          this.userName = userMessage.substring(0,20);
          const welcomeMsg = {
            sender: "system",
            text: "Thank you for setting your name... <br/> Clearing chat in 2 seconds",
            date: moment(new Date).format('hh:mm'),
            primary: false
          }
          this.messages.push(welcomeMsg);
          // this.spinnerOn = false;
          setTimeout(() => {this.messages=[
              {
                sender: "system",
                text: "Please enter the number you were provided",
                date: moment(new Date).format('hh:mm'),
                primary: false
              }];
              this.passNumber = "";
              this.spinnerOn = false;
              if (!this.clientId) {
                this.clientId = ""
              }
          }, 2000)
          return;
        }
        if (!this.passNumber) {
          this.passNumber = userMessage.substring(0,20);
          if (!this.clientId || (this.clientId.indexOf(this.passNumber) === -1))
            this.clientId = this.passNumber + Math.random().toString(36).substring(2,16);
          const welcomeMsg = {
            sender: "system",
            text: "Thank you for entering that... Loading",
            date: moment(new Date).format('hh:mm'),
            primary: false
          }
          this.messages.push(welcomeMsg);
          // this.spinnerOn = false;
          setTimeout(() => {this.messages=[
              {
                sender: "system",
                text: "Welcome! from now on the AI will respond.",
                date: moment(new Date).format('hh:mm'),
                primary: false
              }]
              this.spinnerOn = false;
          }, 2000)
          return;
        }
        this.socket.emit('message', {
          message: userMessage,
          code: this.passNumber,
          client_id: this.clientId,
          aiConfig: this.aiConfig});
      }
    }
  },
  created() {
    this.socket = io({
      path: "/backend/socket.io"
    });// Connect to the WebSocket server
    this.socket.on("connect", () => {
      this.socket.emit("myevent", {data: "Yay COnnected"})
    });
    this.socket.on("configs_list", configs => {
      console.log("configs", configs)
      this.configsList = configs.response;
      this.aiConfig = configs.response[0] || '';
    });
    this.socket.on('response', response => {
      this.spinnerOn = false;
      const serverResponse = response.response;
      if (serverResponse.indexOf("Invalid Pass Number") > -1) {
        this.passNumber = "";
        this.clientId = "";
        this.userNameSet = false;
        this.spinnerOn = false;
        this.messages = [{
          sender: "system",
          text: "We're sorry, the number you provided is incorrect or expired,<br/> please refresh and try again",
          date: moment(new Date).format('hh:mm'),
          primary: false
        }]
        return;
      }
      const botResponse = {
            sender: "bot",
            text: serverResponse,
            date: moment(new Date).format('hh:mm'),
            primary: false
          }
      this.messages.push(botResponse);
    });
  },
  beforeUnmount() {
    this.socket.close(); // Disconnect from the WebSocket server
  }
};
</script>
<style>
.airplane {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  vertical-align: middle;
  display: block;
  text-align:center;
  opacity: 50%;
}
/*.send-button {*/
/*  padding:0;*/
/*  background-color: #CDCDCD;*/
/*  border: none;*/
/*  border-radius: 30%;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  cursor: pointer;*/
/*  position: relative;*/
/*  transition: background-color 0.3s ease;*/
/*}*/

/*.send-button:hover {*/
/*  background-color: #BBBBBB;*/
/*}*/

/*.send-button:hover .paper-airplane {*/
/*  border-color: white;*/
/*}*/

/*.send-button:focus {*/
/*  outline: none;*/
/*}*/

.md-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.md-button-clear {
  background-color: transparent;
  color: #2196f3;
  border: 1px solid #2196f3;
}

.md-button-clear:hover {
  background-color: #2196f3;
  color: #fff;
}

.md-button-clear:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(33, 150, 243, 0.5);
}
.spinner {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #888;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.material-dropdown {
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: fit-content;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.material-dropdown:hover {
  background-color: #eee;
}

.material-dropdown .material-dropdown-content {
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 0 10px;
  min-width: 100%;
  max-height: 200px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.material-dropdown:hover .material-dropdown-content {
  visibility: visible;
  opacity: 1;
}

.material-dropdown .material-dropdown-content::-webkit-scrollbar {
  width: 0;
}

.material-dropdown .material-dropdown-content .material-dropdown-option {
  padding: 10px 15px;
  cursor: pointer;
}

.material-dropdown .material-dropdown-content .material-dropdown-option:hover {
  background-color: #eee;
}

.material-dropdown .material-dropdown-content .material-dropdown-option.selected {
  background-color: #ccc;
  color: #fff;
}
</style>
<style lang="stylus" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto')
borderRadius = 15px
body
  margin 0
  font-family 'Roboto'
.visible
  opacity 1
.colorPanel
  position absolute
  margin 70px auto
  left 0
  right 0
  height 200px
  width 200px
  z-index 3
  background white
  display flex
  flex-wrap wrap
  justify-content center
  align-items center
  border-radius borderRadius
  box-shadow 0 0 30px #aaa
  transition all 0.12s
  &__colorSlot
    box-sizing border-box
    width 33%
    height 33%
    padding 10px
  &__color
    height 100%
    width 100%
    border-radius 100px
    cursor pointer
    background #eee
    transition-duration 0.12s
    &:hover
      opacity 0.7
.chatbox
  display block
  position relative
  max-width 600px
  margin 70px auto
  padding 10px
  background white
  border-radius borderRadius
  box-shadow 0 0 30px #eee
  font-size 17px
  @keyframes blinking
    0%
      opacity 1
    50%
      opacity 0.3
    100%
      opacity 1
  &__header
    display flex
    justify-content center
    align-items center
    flex-wrap nowrap
    font-weight 900
    padding 10px 0
    width 100%
    text-align center
    cursor default
    justify-content space-between
    border-bottom 2px solid #f5f5f5
  &__headerText
      width 100%
      display flex
      justify-content center
      align-items center
      padding-left 20px
  &__onlineDot
    display block
    background #00C853
    height 10px
    width 10px
    border-radius 10px
    margin-left 5px
    animation-name blinking
    animation-duration 1.5s
    animation-iteration-count infinite
  &__tooltip
    opacity 0
    z-index 2
    width auto
    font-size 12px
    text-align center
    padding 3px 10px
    cursor default
    position absolute
    color white
    box-sizing border-box
    border-radius borderRadius
    background #111
    transition all 0.14s
    &--bottom
      top 115%
      margin-left 110px
      opacity 0.2
    &--left
      right calc(100%+5px)
      right -moz-calc(100%+5px)
      margin-left 5px
      top 5px
  &__messages
    width 100%
    padding 10px
    box-sizing border-box
  &__messageBox
    width 100%
    margin-top 5px
    position relative
    &--primary
      text-align right
  &__message
    position relative
    display inline-block
    padding 5px 20px
    border-radius borderRadius
    cursor default
    background #f1f1f1
    transition all 0.12s
    &:hover
      opacity 0.7
      .chatbox__tooltip
        opacity 1
    &--primary
      color white
  &__inputPanel
    width 100%
    position relative
    display flex
    justify-content space-between
    align-items center
    padding-top 10px;
  &__input
    font-family 'Roboto'
    font-size 1em
    width 100%
    border none
    background #f3f3f3
    margin 0 10px
    padding 5px 15px
    color #444
    border-radius borderRadius
    box-sizing border-box
  &__button
    display inline-block
    height 20px
    width 22px
    color white
    border-radius borderRadius
    background #0084ff
    cursor pointer
    font-weight 900
    transition-duration 0.1s
    &:hover
      opacity 0.7
@media screen and (max-width: 450px)
  .chatbox
    box-sizing border-box
    margin 0
    max-width 100%
    width 100%
    border-radius 0
    height 100%
</style>

<style>
.chat-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
}

.chat-input {
  flex: 1;
  padding: 8px;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  resize: none;
  overflow: hidden;
}

.send-button {
  width: 30px;
  height: 30px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* Optional: Adjust styles for mobile devices */
@media (max-width: 600px) {
  .chat-container {
    align-items: stretch;
  }

  .send-button {
    margin-top: 10px;
    margin-left: 0;
  }
}
</style>