<template>
  <div id="app">
    <ChatApp/>
  </div>
</template>
<script>
import ChatApp from './components/ChatApp.vue';
// import { io } from 'socket.io-client';

export default {
  components: {
    ChatApp
  },
  created() {
    // this.socket = io(); // Connect to the WebSocket server
  },
  beforeUnmount() {
    // this.socket.close(); // Disconnect from the WebSocket server
  }
};
</script>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css?family=Roboto')
borderRadius = 15px
body
  margin 0
  font-family 'Roboto'
</style>
